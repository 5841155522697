import React, { useEffect, useMemo, useState } from 'react';
import { Notification, PageContainer, PageHeader, PageHeaderTitle } from '../../../components';
import { useLoggedInUser, useNotifications, usePromises, useStore } from '../../../hooks';
import { APIService, LocalStorageService } from '../../../services';
import { useTranslation } from 'react-i18next';
import { GoGraph } from 'react-icons/go';
import {
    DragDropContext,
    DragStart,
    DropResult,
    OnDragEndResponder,
    OnDragStartResponder,
} from 'react-beautiful-dnd';
import { Case, StatusListItem } from '../../../types';
import { Container, NoStatusSelected, NoStatusSelectedLabel, Wrapper } from './DashboardTable-styles';
import { DashboardTableActions, DashboardTableColumn } from './components';
import { useWindowSize } from 'usehooks-ts';

type DashboardConfigColumn = {
    status: StatusListItem;
    casesIds: Array<string>;
};

export type DashboardConfig = {
    columns: {
        [key: string]: DashboardConfigColumn;
    };
    columnsOrder: Array<string>;
};

const queries = [{ employees: false, clients: true }, { assets: true }];

const DashboardTable = () => {
    const { user } = useLoggedInUser();
    const { t } = useTranslation();
    const { width: windowWidth } = useWindowSize();
    const [isInitialized, setIsInitialized] = useState(false);
    const { statusList } = useStore().caseData;
    const { notifications, addNotification, removeNotification } = useNotifications();
    const [promises, [cases]] = usePromises(() => APIService.cases().getList(...queries));
    const [dashboardConfig, setDashboardConfig] = useState<DashboardConfig>({
        columns: {},
        columnsOrder: [],
    });
    const [draggedCase, setDraggedCase] = useState<null | Case>(null);

    const casesMap = useMemo(() => {
        return new Map((cases.data ?? []).map((caseItem) => [caseItem.id, caseItem]));
    }, [cases.data]);

    useEffect(() => {
        if (cases.data != null && isInitialized === false) {
            initializeDashboardHandler();
            setIsInitialized(true);
        }
    }, [cases.data, statusList]);

    useEffect(() => {
        if (isInitialized === true) {
            LocalStorageService.saveDashboardConfig(user.email, dashboardConfig);
        }
    }, [dashboardConfig]);

    const initializeDashboardHandler = () => {
        const storedDashboardConfig = LocalStorageService.getDashboardConfig(user.email) as DashboardConfig;
        if (storedDashboardConfig != null) {
            const validatedConfig = validateDashboardConfig(storedDashboardConfig);
            setDashboardConfig(validatedConfig);
            return;
        }

        setDashboardConfig(getDefaultDashboardConfig());
    };

    const getDefaultDashboardConfig = (): DashboardConfig => {
        return {
            columns: statusList.reduce(
                (list, status) => ({
                    ...list,
                    [status.id]: {
                        isDisplayed: true,
                        status: status,
                        casesIds: cases.data
                            .filter((caseItem) => caseItem.status.id === status.id)
                            .map((caseItem) => caseItem.id),
                    },
                }),
                {}
            ),
            columnsOrder: statusList.map((status) => status.id),
        };
    };

    const validateDashboardConfig = (config: DashboardConfig): DashboardConfig => {
        if (config.columnsOrder.every((columnId) => checkStatus(columnId)) !== true) {
            return getDefaultDashboardConfig();
        }

        const columnsKeys = Object.keys(config.columns);
        if (columnsKeys.length !== 14 || columnsKeys.length !== statusList.length) {
            return getDefaultDashboardConfig();
        }
        if (columnsKeys.every((columnId) => checkStatus(columnId)) !== true) {
            return getDefaultDashboardConfig();
        }

        const validatedCases = columnsKeys.map((columnId) =>
            config.columns[columnId].casesIds.every((caseId) =>
                cases.data.some((caseItem) => caseItem.id === caseId)
            )
        );
        if (validatedCases.includes(false)) {
            return getDefaultDashboardConfig();
        }

        const updatedConfig: DashboardConfig = {
            ...config,
            columns: statusList.reduce(
                (list, status) => ({
                    ...list,
                    [status.id]: {
                        ...config.columns[status.id],
                        casesIds: config.columns[status.id].casesIds.filter((caseId) => {
                            const caseItem = cases.data.find((caseDataItem) => caseDataItem.id === caseId);
                            return caseItem?.status.id === status.id;
                        }),
                    },
                }),
                {}
            ),
        };

        for (const caseItem of cases.data) {
            if (updatedConfig.columns[caseItem.status.id].casesIds.includes(caseItem.id) !== true) {
                updatedConfig.columns[caseItem.status.id].casesIds.push(caseItem.id);
            }
        }

        return updatedConfig;
    };

    const checkStatus = (statusId: string): boolean => {
        return statusList.some((status) => status.id === statusId);
    };

    const onSelectStatusHandler = (updatedSelectedStatus: Array<string>) => {
        setDashboardConfig((prev) => ({
            ...prev,
            columnsOrder: updatedSelectedStatus,
        }));
    };

    const onDragStartHandler: OnDragStartResponder = ({ draggableId }: DragStart) => {
        const currentCase = cases.data.find((item) => item.id === draggableId) ?? null;
        setDraggedCase(currentCase);
    };

    const onDragEndHandler: OnDragEndResponder = async ({ destination, source, draggableId }: DropResult) => {
        if (destination == null) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const start = dashboardConfig.columns[source.droppableId];
        const end = dashboardConfig.columns[destination.droppableId];

        if (start === end) {
            const column = dashboardConfig.columns[source.droppableId];
            const newCasesIds = [...column.casesIds];
            newCasesIds.splice(source.index, 1);
            newCasesIds.splice(destination.index, 0, draggableId);
            return setDashboardConfig((prev) => ({
                ...prev,
                columns: {
                    ...prev.columns,
                    [column.status.id]: { ...prev.columns[column.status.id], casesIds: newCasesIds },
                },
            }));
        }

        const startCasesIds = [...start.casesIds];
        startCasesIds.splice(source.index, 1);

        const endCasesIds = [...end.casesIds];
        endCasesIds.splice(destination.index, 0, draggableId);

        setDashboardConfig((prev) => ({
            ...prev,
            columns: {
                ...prev.columns,
                [start.status.id]: {
                    ...start,
                    casesIds: startCasesIds,
                },
                [end.status.id]: {
                    ...end,
                    casesIds: endCasesIds,
                },
            },
        }));
        setDraggedCase(null);

        await APIService.cases().updateCaseStatus(draggableId, destination.droppableId);
        addNotification('dashboard.notifications.status_updated');
    };

    const displayedCasesCount = useMemo(() => {
        return dashboardConfig.columnsOrder.reduce((count, columnId) => {
            const column = dashboardConfig.columns[columnId];
            if (column != null) {
                count += column.casesIds.length;
            }
            return count;
        }, 0);
    }, [dashboardConfig]);

    const renderCounters = () => {
        const hiddenCount = cases.data.length - displayedCasesCount;
        const displayedMessage = t('dashboard.counters.displayed', { count: displayedCasesCount });

        if (hiddenCount === 0) {
            return `(${displayedMessage})`;
        } else {
            const hiddenMessage = t('dashboard.counters.hidden', { count: hiddenCount });
            return `(${displayedMessage} / ${hiddenMessage})`;
        }
    };

    return (
        <Wrapper>
            <Notification notifications={notifications} removeNotification={removeNotification} />
            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display={windowWidth > 700 ? 'row' : 'column'}>
                    <PageHeaderTitle>
                        <GoGraph />
                        {t('dashboard.page_title')} {cases.isLoading === false && renderCounters()}
                    </PageHeaderTitle>
                    <DashboardTableActions
                        dashboardConfig={dashboardConfig}
                        selectedStatus={dashboardConfig.columnsOrder}
                        onSelectStatusHandler={onSelectStatusHandler}
                    />
                </PageHeader>
                <Container className="scrollable-container">
                    <DragDropContext
                        onDragEnd={onDragEndHandler}
                        onDragStart={onDragStartHandler}
                        enableDefaultSensors
                    >
                        {dashboardConfig.columnsOrder.length === 0 && (
                            <NoStatusSelected>
                                <NoStatusSelectedLabel>
                                    {t('dashboard.no_status_selected')}
                                </NoStatusSelectedLabel>
                            </NoStatusSelected>
                        )}

                        {isInitialized === true &&
                            dashboardConfig.columnsOrder.map((columnId, index) => {
                                const column = dashboardConfig.columns[columnId];
                                const columnCases = column.casesIds.map((caseId) => casesMap.get(caseId)!);
                                const status = statusList.find((item) => item.id === column.status.id);
                                return (
                                    <DashboardTableColumn
                                        key={column.status.id}
                                        index={index}
                                        status={status!}
                                        cases={columnCases}
                                        draggedCase={draggedCase}
                                    />
                                );
                            })}
                    </DragDropContext>
                </Container>
            </PageContainer>
        </Wrapper>
    );
};

export default DashboardTable;
