import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalParagraph, ModalFooter } from '../../../../../../components';

type Props = {
    onClose: () => void;
};

const ClientRequiredModal = ({ onClose }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal title={t('case.clients.modals.client_required.title')} onClose={onClose}>
            <ModalParagraph>{t('case.clients.modals.client_required.message')}</ModalParagraph>
            <ModalFooter confirmationLabel="OK" onClick={onClose} />
        </Modal>
    );
};

export default ClientRequiredModal;
