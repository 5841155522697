import React from 'react';
import {
    FormFooter,
    FormGroup,
    FormGroupLabel,
    Modal,
    Form,
    FormCheckboxInputs,
    FormSelectInput,
} from '../../../../../../components';
import { Case, CaseClient } from '../../../../../../types/cases';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCaseClientRoles, usePromises } from '../../../../../../hooks';
import {
    RemoveClientButton,
    RemoveClientButtonContainer,
    TextGroup,
    TextGroupKey,
    TextGroupValue,
} from './UpdateClientModal-styles';
import { generateId } from '../../../../../../tools';
import { AiOutlineDelete } from 'react-icons/ai';
import { APIService } from '../../../../../../services';

type Props = {
    onClose: () => void;
    selectedClient: CaseClient;
    openRemoveClientModal: () => void;
    currentCase: Case;
    refreshClients: () => Promise<void>;
    refreshCurrentCase: () => Promise<void>;
};

const UpdateClientModal = ({
    onClose,
    selectedClient,
    openRemoveClientModal,
    currentCase,
    refreshClients,
    refreshCurrentCase,
}: Props) => {
    const { t } = useTranslation();
    const clientRoles = useCaseClientRoles();
    const [{ error, clearError, executePromise }] = usePromises();

    const data = [
        {
            title: t('generic.firstName'),
            value: selectedClient.firstName,
        },
        {
            title: t('generic.lastName'),
            value: selectedClient.lastName,
        },
        {
            title: t('generic.email'),
            value: selectedClient.email,
        },
        {
            title: t('generic.contact'),
            value: selectedClient.contacts?.map((contact) => contact.phoneNumber).join(', ') ?? '',
        },
    ];

    const validationSchema = Yup.object({
        role: Yup.string().required(t('generic.error.required_field')),
        isMainClient: Yup.string().required(t('generic.error.required_field')),
    });

    const formik = useFormik({
        initialValues: {
            role: selectedClient.role,
            isMainClient: selectedClient.isMainClient ? 'yes' : 'no',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                await APIService.cases().updateClient(currentCase.id, selectedClient.id, {
                    role: submittedValues.role,
                    isMainClient: submittedValues.isMainClient === 'yes' ? true : false,
                });
                await refreshCurrentCase();
                await refreshClients();
                onClose();
            });
        },
    });

    return (
        <Modal
            title={t('case.clients.modals.update.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                {data.map((item) => (
                    <TextGroup key={generateId()}>
                        <TextGroupKey>{item.title}</TextGroupKey>
                        <TextGroupValue>{item.value}</TextGroupValue>
                    </TextGroup>
                ))}

                <FormGroup name="role">
                    <FormGroupLabel>{t('cases.new_case.client_role')}</FormGroupLabel>
                    <FormCheckboxInputs options={clientRoles} multiple={false} />
                </FormGroup>

                <FormGroup name="isMainClient">
                    <FormGroupLabel>{t('generic.main_client')}</FormGroupLabel>
                    <FormSelectInput
                        isDisabled={selectedClient.isMainClient}
                        options={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                        ]}
                    />
                </FormGroup>

                <FormFooter
                    submitLabel={t('generic.update')}
                    secondary={{
                        label: t('generic.cancel'),
                        onClick: onClose,
                    }}
                >
                    {selectedClient.isMainClient !== true && (
                        <RemoveClientButtonContainer onClick={openRemoveClientModal}>
                            <AiOutlineDelete />
                            <RemoveClientButton>{t('case.clients.modals.remove.title')}</RemoveClientButton>
                        </RemoveClientButtonContainer>
                    )}
                </FormFooter>
            </Form>
        </Modal>
    );
};

export default UpdateClientModal;
