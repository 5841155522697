import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const HeaderLeft = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const HeaderLeftInternalId = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    /* color: ${({ theme }) => theme.colors.text.secondary}; */
    font-weight: ${({ theme }) => theme.font.weight.semiBold};

    cursor: default;
`;

export const HeaderLeftStatus = styled.div<{ isClickable: boolean }>`
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};

    ${({ isClickable, theme }) => {
        if (isClickable === true) {
            return {
                cursor: 'pointer',
                ':hover': {
                    textDecoration: 'underline',
                    color: theme.colors.primary.main,
                },
            };
        }
    }}
`;

export const HeaderLeftSelectCase = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};

    > svg {
        margin-right: ${({ theme }) => theme.box.spacing.md};
    }

    ul li {
        height: max-content;
        a {
            color: ${({ theme }) => theme.colors.text.main} !important;
            width: 100%;
        }
    }
`;

export const HeaderRight = styled.div`
    display: flex;
    > * {
        margin-left: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    padding: ${({ theme }) => theme.box.spacing.lg};
    flex: 1;
    background: ${({ theme }) => theme.colors.surface.main};
    display: flex;

    > div {
        flex: 1;
    }
`;

export const CaseChildPageTitle = styled.div`
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    padding-left: ${({ theme }) => theme.box.spacing.lg};
    display: flex;
    align-items: center;
    line-height: 1.4;

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.md};
        transform: translateY(-1px);
    }
`;
